@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css);

:root {
  --primary-color: #0278c2;
  --secondary-color: #131e18;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  background-image: url("./wave.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: rgba(125, 186, 246, 0.05);
}


code {
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 1270px;
  margin-inline: auto;
  padding-inline: 15px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}

.preTitle {
  color: var(--primary-color);
  font-size: 20px;
  border: 1px solid #0278c261;
  padding: 6px;
  padding-left: 10px;
  border-radius: 17% 83% 38% 62% / 0% 83% 17% 100% ;
  font-weight: 500;
  width: fit-content;
  user-select: none;
  
}

.title {
  font-size: 50px;
  font-weight: 700;
  color: var(--secondary-color);
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 35px;
  }
}

         
.btn-grad {
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 26px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #516FE9 #DFE9EB;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #516FE9;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #5D66A3;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #6A6AA3;
}